import React from "react";
import * as styles from "./BannerCard.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';
import classNames from "classnames";

const BannerCard = (props) => {
    const {title, terrior, BannerCard_1, paragraph_1, paragraph_2, paragraph_3, bannerImg, news, vini} = props
    return (
        <div className={`${styles.BannerCard} ${terrior && styles.terriorPage} ${BannerCard_1} ${news && styles.BannerCard_2} ${vini && styles.BannerCard_3} ${vini && styles.BannerCard_3}`}>
            <div className={styles.firstBox}>
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
                <h2 className="animate__animated animate__fadeInUp">{title}</h2>
                </AnimationOnScroll>
                <div>
                <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
                <p className="animate__animated animate__fadeInUp">{paragraph_1}</p>
                </AnimationOnScroll>
                </div>
            </div>
            <div className={styles.secondContent}>
            {bannerImg ? <img src={bannerImg} alt="" /> : null}
            <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
            {paragraph_2 ? <p className={classNames(terrior && styles.secondPara,"animate__animated animate__fadeInUp")}>{paragraph_2}</p> : null}
            {paragraph_3 ? <p className="animate__animated animate__fadeInUp">{paragraph_3}</p> : null}
            </AnimationOnScroll>
            </div>
            
        </div>
    );
};

export default BannerCard;
