// extracted by mini-css-extract-plugin
export var BannerSection = "TenutaBanner-module--BannerSection--1c3e1";
export var TextContent = "TenutaBanner-module--TextContent--c85c1";
export var TextContent2 = "TenutaBanner-module--TextContent2--2656c";
export var TextContent3 = "TenutaBanner-module--TextContent3--6c615";
export var VisitContent = "TenutaBanner-module--VisitContent--7295c";
export var bannerContent = "TenutaBanner-module--bannerContent--ffca5";
export var bannerImg = "TenutaBanner-module--bannerImg--b4c88";
export var displayNone = "TenutaBanner-module--displayNone--99467";
export var pressSection = "TenutaBanner-module--pressSection--c1f4c";
export var tenutaParra = "TenutaBanner-module--tenutaParra--63b84";
export var viniSection = "TenutaBanner-module--viniSection--73da2";