import classNames from "classnames";
import React from "react";
import * as styles from "../HighlightText/HighLightText.module.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const HighlightText = ({ Title, persone }) => {
  return (
    <div
      className={classNames(
        styles.HighlightText,
        persone && styles.personePage
      )}
    >
      <AnimationOnScroll animateIn="animate__animated animate__fadeInUp">
        <h1 className="animate__animated animate__fadeInUp">
          <span>{Title}</span>
        </h1>
      </AnimationOnScroll>
    </div>
  );
};

export default HighlightText;
