import React, { useContext } from "react";
import * as styles from "./PressSingola.module.css";
import TenutaBanner from "../../Components/TenutaBanner/TenutaBanner";
import HighlightText from "../../Components/HighlightText/HighlightText";
import BannerCard from "../../Components/BannerCard/BannerCard";
import Header from "../../Components/Header/Header";
import Footer from "../../Components/Footer/Footer";
import { graphql } from "gatsby";
import { SEO } from "../../Components/Seo/seo";
import { languageContext } from "../../Components/Header/Header";

const Index = ({ data }) => {
  const llanguage = useContext(languageContext);
  const { language } = llanguage;
  const localizations = data?.strapiArticle?.localizations || {};
  // const pressLocalizations = data.allStrapiPress.edges[0]?.node?.localizations || {};
  const { Article: localArticle = [] } = localizations?.data[0]?.attributes;
  const { Article = [] } =
    language === "it"
      ? localizations?.data[0]?.attributes
      : data?.strapiArticle || {};
  // const { PressKitDescription } =
  //   language === "it"
  //     ? pressLocalizations?.data[0]?.attributes
  //     : data?.allStrapiPress?.edges[0]?.node || {};
  const mapArticle = language === "it" ? localArticle : Article || {};
  // const { PressKitPDF } = data.allStrapiScaricaPressKit.edges[0].node || {};

  return (
    <>
      <div className="pageWrapper">
        <Header />

        <div className={styles.HeroSection}>
          <TenutaBanner
            bannerImg={
              language === "it"
                ? localArticle[0]?.Image?.data?.attributes?.url
                : Article[0]?.Image?.url
            }
            vini={true}
            press={true}
            bannerTitle={Article[0]?.Title}
            bannerAuthor={Article[0]?.ParaGraph1}
          />
        </div>

        <HighlightText Title={Article[0]?.ParaGraph2} />

        <div className={styles.BannerSection}>
          {mapArticle?.slice(1)?.map((item) => (
            <BannerCard
              title={item?.Title}
              bannerImg={
                language === "it"
                  ? item?.Image?.data?.attributes?.url
                  : item?.Image?.url
              }
              news={true}
              // BannerCard_1={styles.BannerCard_1}
              paragraph_1={item?.ParaGraph1}
              paragraph_2={item?.ParaGraph2}
              paragraph_3={item?.ParaGraph3}
            />
          ))}
        </div>

        {/* <BannerCard
              newsBanner_2={true}
               news={true}
                title="Vendemmia"
                bannerImg={bannerImg3}
                paragraph_1="La vendemmia è iniziata i primi di settembre con il Sauvignon per preservare la freschezza e l’aromaticità varietale. A seguire sono state raccolte le parcelle di Chardonnay, Friulano, Malvasia e per ultima tra i bianchi la Ribolla Gialla, più lenta a maturare. Abbiamo concluso la prima settimana di ottobre con lo Schioppettino, unica varietà a bacca rossa in azienda, che è stato raccolto con ottima maturazione fenolica grazie alle belle giornate settembrine.
                "
                />
            <BannerCard
            newsBanner_3={true}
               news={true}
                title="Dalla cantina"
                paragraph_1="Gli assaggi di vasca denotano vini piacevoli, profumati e con un tenore di acidità oltre le aspettative. Le strutture sono solide, innervate da un’ottima mineralità, e promettono vini bilanciati e di grande beva.
                "
                /> */}

        {/* <ButtonKit
        description={PressKitDescription}
        button={language === "it" ? "Scarica press kit" : "Download press kit"}
        downloadPdf={PressKitPDF?.url}
      /> */}
      </div>
      <Footer />
    </>
  );
};

export default Index;

export const query = graphql`
  query MyQuery($slug: String) {
    strapiArticle(slug: { eq: $slug }) {
      Article {
        Image {
          url
        }
        ParaGraph1
        ParaGraph2
        ParaGraph3
        Title
      }
      localizations {
        data {
          attributes {
            Article {
              Image {
                data {
                  attributes {
                    url
                  }
                }
              }
              ParaGraph1
              ParaGraph2
              ParaGraph3
              Title
            }
          }
        }
      }
      slug
    }
    allStrapiPress {
      edges {
        node {
          DownloadButtonLink {
            href
            label
          }
          PageTitle
          PressKitDescription
          localizations {
            data {
              attributes {
                PageTitle
                PressKitDescription
              }
            }
          }
        }
      }
    }
  }
  `;

export const Head = ({ data }) => {
  const { Article = [] } = data?.strapiArticle || {};
  return <SEO title={Article[0]?.Title} description={Article[0]?.ParaGraph1} />;
};

  // allStrapiScaricaPressKit {
  //   edges {
  //     node {
  //       PressKitPDF {
  //         url
  //       }
  //     }
  //   }
  //}