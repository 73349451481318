import React from "react";
import * as styles from "./TenutaBanner.module.css";
// import bannerImg from '../../assets/images/TenutaBanner.png'
// import arrow from "../../assets/images/arrow-right.png";
import classNames from "classnames";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css';

const TenutaBanner = ({
  persone,
  terrior,
  bannerTitle,
  vini,
  bannerImg,
  bannerAuthor,
  bannerDescription,
  press,
}) => {
  return (
    <div className={classNames(styles.BannerSection, press && styles.pressSection, vini && styles.viniSection)}>
      <div
        className={`${styles.TextContent} ${terrior && styles.TextContent2} ${
          persone && styles.TextContent3
        }`}
      >
        {press ? <h6>{bannerAuthor}</h6> : null}
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <h2 className="animate__animated animate__fadeInUp">{bannerTitle}</h2>
        </AnimationOnScroll>
        <AnimationOnScroll  animateIn='animate__animated animate__fadeInUp'>
        <p className="animate__animated animate__fadeInUp">{bannerDescription}</p>
        </AnimationOnScroll>
      </div>

      <div className={terrior ? styles.displayNone : styles.bannerContent}>
        <img className={styles.bannerImg} src={bannerImg} alt="" />
        {/* {vini ? null : (
          <div className={styles.VisitContent}>
            <div className="">
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
            </div>
            <div>
              <span>Scopri le visite</span>
              <img src={arrow} alt="" />
            </div>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default TenutaBanner;
